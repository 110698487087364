import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { graphql } from "gatsby";
import React from "react";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import Pin from "../../assets/icons/pin.svg";
import QuestionBubble from "../../assets/icons/questionBubble.svg";
import ShoppingBag from "../../assets/icons/shoppingBag.svg";
import Logo from "../../assets/images/logo_oneliner_black.svg";
import Breakpoint from "../../components/breakpoint";
import usePageHome from "../../hooks/usePage/usePageHome";
import useSettings from "../../hooks/useSettings";
import Link from "../link";
import LanguageSwitcher from "./_footer/languageSwitcher";
import NavItem, { NavItemAsLink } from "./_header/_desktop/navItem";
import NavRest from "./_header/_desktop/navRest";
import NavItemMobile, { NavItemMobileAsLink } from "./_header/_mobile/navItem";
import NavRestMobile from "./_header/_mobile/navRest";
import Cart from "./_header/cart";

const Icon: React.FC<{ name: string }> = ({ name }) => {
  switch (name) {
    case "pin":
      return <Pin width="20" height="20" />;
    case "questionBubble":
      return <QuestionBubble width="20" height="20" />;
    case "shoppingBag":
      return <ShoppingBag width="20" height="20" />;
    default:
      console.error(`Unknown icon: ${name}`);
      return null;
  }
};

// Components __________________________________________________________________________________________________________
const HeaderMobile: React.FC = () => {
  const settings = useSettings();
  const toHome = usePageHome();

  const cart = settings.items?.[settings.items.length - 1];
  if (!cart) return null;

  return (
    <header className="sticky top-0 m-auto w-full border-b p-l-sm-fluid p-r-md-fluid z-[99] h-[72px] border-b-black-10 bg-white-default">
      <nav className="flex justify-between items-center h-full">
        <Link link={toHome} className="border-b-0">
          <Logo width="179" height="16" />
        </Link>
        <div className="flex overflow-hidden overscroll-contain items-center h-full">
          <Cart item={cart} withTitle={false} />
          <Menu>
            {({ open }) => {
              return (
                <>
                  <div
                    className={twJoin(
                      "base-transition-200 fade-in absolute left-0 top-0 h-[100lvh] w-full overflow-hidden bg-black-40 no-scrollbar",
                      open ? "bg-black-40" : "pointer-events-none bg-transparent",
                    )}
                  >
                  </div>
                  <MenuButton className="flex relative z-10 pr-0 pl-4 ml-3.5 w-10 h-10 top-[15px]" title="menu">
                    <div id="hamburger" className={twJoin(open && "open")}>
                      <span />
                      <span />
                      <span />
                    </div>
                  </MenuButton>
                  <MenuItems
                    static
                    className={twJoin(
                      "absolute right-[-319px] top-0 h-[100lvh] max-h-[100lvh] w-[319px] justify-start overflow-hidden overscroll-contain bg-white-default pt-[71px] no-scrollbar",
                      open ? "slide-out translate-x-[-100%]" : "slide-in !w-0",
                    )}
                  >
                    {settings.nav_one_menu_as_link
                      ? (
                        <NavItemMobileAsLink
                          title={settings.nav_one_title}
                          link={settings.nav_one_link}
                          className="border-t border-t-black-10"
                        />
                      )
                      : (
                        <NavItemMobile
                          title={settings.nav_one_title}
                          body={settings.body2}
                          className="border-t border-t-black-10"
                        />
                      )}
                    {settings.nav_two_menu_as_link
                      ? <NavItemMobileAsLink title={settings.nav_two_title} link={settings.nav_two_link} />
                      : <NavItemMobile title={settings.nav_two_title} body={settings.body3} />}
                    {settings.nav_three_menu_as_link
                      ? <NavItemMobileAsLink title={settings.nav_three_title} link={settings.nav_three_link} />
                      : <NavItemMobile title={settings.nav_three_title} body={settings.body1} />}
                    <NavRestMobile items={settings.items?.slice(0, settings.items.length - 1)} />
                    <div className="!p-b-xl-fluid absolute bottom-0 z-50 flex w-full justify-start border-b-0 border-t border-t-black-10 bg-white-default p-5 text-link_sm">
                      <LanguageSwitcher />
                    </div>
                  </MenuItems>
                </>
              );
            }}
          </Menu>
        </div>
      </nav>
    </header>
  );
};

export type Navigation = "closed" | 1 | 2 | 3;

const Header: React.FC = () => {
  const settings = useSettings();
  const toHome = usePageHome();
  const [navigation, setNavigation] = useState<Navigation>("closed");

  const mobile = <HeaderMobile />;

  const isOpen = navigation !== "closed";

  const closeNavigation = () => setNavigation("closed");
  const toggleNavigation = (index: 1 | 2 | 3) => {
    setNavigation(navigation === index ? "closed" : index);
  };

  const desktop = (
    <header
      onMouseLeave={() => closeNavigation()}
      className="sticky top-0 w-full border-b group z-[999] h-[72px] border-b-black-10 bg-white-default"
    >
      <nav className="absolute z-40 w-full group">
        <div className="items-center m-auto p-l-sm-fluid p-r-md-fluid baseGrid max-w-screen-max">
          <div className="flex z-50 col-span-10 justify-start">
            <div className="flex">
              {settings.nav_one_menu_as_link
                ? <NavItemAsLink title={settings.nav_one_title} link={settings.nav_one_link} />
                : (
                  <NavItem
                    title={settings.nav_one_title}
                    body={settings.body2}
                    openNavigation={() => setNavigation(1)}
                    closeNavigation={() => closeNavigation()}
                    toggleNavigation={() => toggleNavigation(1)}
                    state={{ isOpen, itemOpen: navigation === 1 }}
                  />
                )}
              {settings.nav_two_menu_as_link
                ? <NavItemAsLink title={settings.nav_two_title} link={settings.nav_two_link} />
                : (
                  <NavItem
                    title={settings.nav_two_title}
                    body={settings.body3}
                    openNavigation={() => setNavigation(2)}
                    closeNavigation={() => closeNavigation()}
                    toggleNavigation={() => toggleNavigation(2)}
                    state={{ isOpen, itemOpen: navigation === 2 }}
                  />
                )}
              {settings.nav_three_menu_as_link
                ? <NavItemAsLink title={settings.nav_three_title} link={settings.nav_three_link} />
                : (
                  <NavItem
                    title={settings.nav_three_title}
                    body={settings.body1}
                    openNavigation={() => setNavigation(3)}
                    closeNavigation={() => closeNavigation()}
                    toggleNavigation={() => toggleNavigation(3)}
                    state={{ isOpen, itemOpen: navigation === 3 }}
                  />
                )}
            </div>
          </div>
          <Link
            link={toHome}
            onClick={() => closeNavigation()}
            className="flex col-span-4 justify-self-center border-b-0"
          >
            <Logo height="20" width="238" />
          </Link>
          <NavRest items={settings.items} className="flex col-span-10 justify-end" closeNavigation={closeNavigation} />
        </div>
      </nav>
      <section
        className={twJoin(
          "base-transition-300 absolute left-0 top-[72px] z-30 h-[365px] w-full overflow-hidden bg-white-default",
          isOpen ? "bottom-0 h-[365px]" : "bottom-[365px] !h-0",
        )}
      >
      </section>
    </header>
  );

  return <Breakpoint mobile={mobile} desktop={desktop} />;
};

export const query = graphql`
  fragment Header on PrismicDomainData {
    ...MenuItems
    ...MenuRest
  }
`;

export default Header;

export { Icon };
