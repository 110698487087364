import React from "react";

type Props = {
  mobile: React.ReactNode;
  desktop: React.ReactNode;
  breakpoint?: "md" | "lg";
};

const Breakpoint: React.FC<Props> = ({ mobile, desktop, breakpoint = "lg" }) => {
  switch (breakpoint) {
    case "md":
      return (
        <>
          <div className="contents md:hidden">{mobile}</div>
          <div className="hidden md:contents">{desktop}</div>
        </>
      );
    case "lg":
      return (
        <>
          <div className="contents lg:hidden">{mobile}</div>
          <div className="hidden lg:contents">{desktop}</div>
        </>
      );
  }
};

export default Breakpoint;
