import { type DocumentNode, print } from "graphql";
import cartFragment from "./fragments/cart.graphql";

import addLineItemsMutation from "./operations/cart/addLineItems.graphql";
import createCartMutation from "./operations/cart/createCart.graphql";
import discountCodesMutation from "./operations/cart/discountCodes.graphql";
import getCartQuery from "./operations/cart/getCart.graphql";
import removeLineItemsMutation from "./operations/cart/removeLineItems.graphql";
import updateLineItemsMutation from "./operations/cart/updateLineItems.graphql";
import getProductQuery from "./operations/product/getProduct.graphql";

// Get the fragment definition as a string
const cartFragmentString = print(cartFragment);

// Function to combine a query with fragment definitions
const withFragments = (doc: DocumentNode) => {
  const queryString = print(doc);
  // Only add the fragment if the query uses it
  if (queryString.includes("...CartFragment")) {
    return `${cartFragmentString}\n${queryString}`;
  }
  return queryString;
};

// Apply to all operations
export const CartOperations = {
  GetCart: withFragments(getCartQuery),
  CreateCart: withFragments(createCartMutation),
  AddLineItems: withFragments(addLineItemsMutation),
  UpdateLineItems: withFragments(updateLineItemsMutation),
  RemoveLineItems: withFragments(removeLineItemsMutation),
  ApplyDiscountCodes: withFragments(discountCodesMutation),
};

export const ProductOperations = {
  GetProduct: print(getProductQuery), // No fragment used here
};
