import type {
  CartDiscountCodesUpdateMutation,
  CartLinesAddMutation,
  CartLinesRemoveMutation,
  CartLinesUpdateMutation,
} from "../../@types/shopify-types";
import addToCartEvent from "../../events/addToCart";
import initiateCheckoutEvent from "../../events/initiateCheckout";
import removeFromCartEvent from "../../events/removeFromCart";
import viewCartEvent from "../../events/viewCart";
import { useShopify, useShopifyDispatch } from "./context";
import { CartOperations } from "./graphql";

import type { CartLine, CartLineInput } from "./types";

export function useCart() {
  const { client, cart, status } = useShopify();
  const dispatch = useShopifyDispatch();

  /**
   * Add items to cart
   * @param cartLines - Array of cart line inputs conforming to Shopify's CartLineInput type
   */
  const addLineItems = async (cartLines: CartLineInput[]) => {
    if (!cart) return;
    if (cartLines.length === 0) return;

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      const { data, errors } = await client.request<CartLinesAddMutation>(CartOperations.AddLineItems, {
        variables: {
          cartId: cart.id,
          lines: cartLines,
        },
      });

      if (errors || data?.cartLinesAdd?.userErrors?.length) {
        const errorMessage =
          (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
          data?.cartLinesAdd?.userErrors?.[0]?.message ||
          "Error adding items to cart";
        console.error("🚨 → 🛒 → %cFailed to add items to cart", "font-style: italic", errorMessage);
        throw new Error(errorMessage);
      }

      if (!data?.cartLinesAdd?.cart) {
        console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
        throw new Error("No cart data returned");
      }

      console.info("🛒 → %cItems added to cart", "font-style: italic", cartLines);

      const updatedCart = data.cartLinesAdd.cart;

      // Find the newly added items
      const addedItems = updatedCart.lines.edges
        .map((edge) => edge.node)
        .filter((line) => cartLines.some((input) => input.merchandiseId === line.merchandise.id));

      if (addedItems.length > 0) {
        addToCartEvent({
          items: addedItems.map((line) => ({
            id: line.merchandise.product.id,
            // Use the unit price directly from the merchandise
            price: Number.parseFloat(line.merchandise.price.amount),
            name: line.merchandise.product.title,
            category: line.merchandise.product.productType || "",
            quantity: line.quantity,
          })),
          value: addedItems.reduce(
            (sum, line) => sum + (Number.parseFloat(line.merchandise.price.amount) * line.quantity),
            0,
          ),
          currency: updatedCart.cost.totalAmount.currencyCode,
        });
      }

      dispatch({ type: "set_cart", payload: updatedCart });
    } catch (error) {
      console.error("🚨 → 🛒 → %cException adding items to cart", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  const addItem = (variantId: string, quantity: number) => {
    return addLineItems([
      {
        merchandiseId: variantId,
        quantity,
      },
    ]);
  };

  /**
   * Update quantity of a line item in cart
   * @param line - The cart line to update
   * @param quantity - The new quantity
   */
  const updateQuantity = async (line: CartLine, quantity: number) => {
    if (!cart) return;

    // If quantity is zero or negative, remove the item
    if (quantity < 1) {
      return removeLineItems([line.id]);
    }

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      const { data, errors } = await client.request<CartLinesUpdateMutation>(CartOperations.UpdateLineItems, {
        variables: {
          cartId: cart.id,
          lines: [
            {
              id: line.id,
              quantity,
            },
          ],
        },
      });

      if (errors || data?.cartLinesUpdate?.userErrors?.length) {
        const errorMessage =
          (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
          data?.cartLinesUpdate?.userErrors?.[0]?.message ||
          "Error updating item quantity";
        console.error("🚨 → 🛒 → %cFailed to update item quantity", "font-style: italic", errorMessage);
        throw new Error(errorMessage);
      }

      if (!data?.cartLinesUpdate?.cart) {
        console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
        throw new Error("Failed to update item quantity");
      }

      console.info("🛒 → %cItem quantity updated", "font-style: italic", { lineId: line.id, quantity });

      // If increasing quantity, track as add to cart
      if (quantity > line.quantity) {
        const addedQuantity = quantity - line.quantity;
        const price = Number.parseFloat(line.merchandise.price.amount);

        addToCartEvent({
          items: [{
            id: line.merchandise.product.id,
            price: price,
            name: line.merchandise.product.title,
            category: line.merchandise.product.productType,
            quantity: addedQuantity,
          }],
          value: price * addedQuantity,
          currency: data.cartLinesUpdate.cart.cost.totalAmount.currencyCode,
        });
      } // If decreasing quantity, track as remove from cart
      else if (quantity < line.quantity) {
        const removedQuantity = line.quantity - quantity;
        const price = Number.parseFloat(line.merchandise.price.amount);

        removeFromCartEvent({
          items: [{
            id: line.merchandise.product.id,
            price: price,
            name: line.merchandise.product.title,
            category: line.merchandise.product.productType,
            quantity: removedQuantity,
          }],
          value: price * removedQuantity,
          currency: data.cartLinesUpdate.cart.cost.totalAmount.currencyCode,
        });
      }

      dispatch({ type: "set_cart", payload: data.cartLinesUpdate.cart });
    } catch (error) {
      console.error("🚨 → 🛒 → %cException updating item quantity", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  /**
   * Remove line items from cart
   * @param lineIds - Array of line IDs to remove
   */
  const removeLineItems = async (lineIds: string[]) => {
    if (!cart) return;
    if (lineIds.length === 0) return;

    // Store items before removal for event tracking
    const itemsToRemove = cart.lines.edges
      .map((edge) => edge.node)
      .filter((line) => lineIds.includes(line.id));

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      const { data, errors } = await client.request<CartLinesRemoveMutation>(CartOperations.RemoveLineItems, {
        variables: {
          cartId: cart.id,
          lineIds: lineIds,
        },
      });

      if (errors || data?.cartLinesRemove?.userErrors?.length) {
        const errorMessage =
          (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
          data?.cartLinesRemove?.userErrors?.[0]?.message ||
          "Error removing items from cart";
        console.error("🚨 → 🛒 → %cFailed to remove items from cart", "font-style: italic", errorMessage);
        throw new Error(errorMessage);
      }

      if (!data?.cartLinesRemove?.cart) {
        console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
        throw new Error("Failed to remove items from cart");
      }

      console.info("🛒 → %cItems removed from cart", "font-style: italic", lineIds);

      // Fire remove from cart event
      if (itemsToRemove.length > 0) {
        removeFromCartEvent({
          items: itemsToRemove.map((line) => ({
            id: line.merchandise.product.id,
            price: Number.parseFloat(line.merchandise.price.amount),
            name: line.merchandise.product.title,
            category: line.merchandise.product.productType,
            quantity: line.quantity,
          })),
          value: itemsToRemove.reduce(
            (sum, line) => sum + (Number.parseFloat(line.merchandise.price.amount) * line.quantity),
            0,
          ),
          currency: cart.cost.totalAmount.currencyCode,
        });
      }

      dispatch({ type: "set_cart", payload: data.cartLinesRemove.cart });
    } catch (error) {
      console.error("🚨 → 🛒 → %cException removing items from cart", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  /**
   * Apply a discount code to cart
   * @param discountCode - The discount code to apply
   */
  const applyDiscountCode = async (discountCode: string) => {
    if (!cart) return;
    if (!discountCode.trim()) return;

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      // Get existing discount codes if any
      const currentCodes = cart.discountCodes?.filter((code) => code.applicable) || [];
      const currentCodeValues = currentCodes.map((code) => code.code);

      // Add the new code if it's not already applied
      if (!currentCodeValues.includes(discountCode)) {
        const newCodes = [...currentCodeValues, discountCode];

        const { data, errors } = await client.request<CartDiscountCodesUpdateMutation>(
          CartOperations.ApplyDiscountCodes,
          {
            variables: {
              cartId: cart.id,
              discountCodes: newCodes,
            },
          },
        );

        if (errors || data?.cartDiscountCodesUpdate?.userErrors?.length) {
          const errorMessage =
            (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
            data?.cartDiscountCodesUpdate?.userErrors?.[0]?.message ||
            "Error applying discount code";
          console.error("🚨 → 🛒 → %cFailed to apply discount code", "font-style: italic", errorMessage);
          throw new Error(errorMessage);
        }

        if (!data?.cartDiscountCodesUpdate?.cart) {
          console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
          throw new Error("Failed to apply discount code");
        }

        console.info("🛒 → %cDiscount code applied", "font-style: italic", discountCode);
        dispatch({ type: "set_cart", payload: data.cartDiscountCodesUpdate.cart });
      }
    } catch (error) {
      console.error("🚨 → 🛒 → %cException applying discount code", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  /**
   * Remove a specific discount code from cart
   * @param discountCode - The discount code to remove
   */
  const removeDiscountCode = async (discountCode: string) => {
    if (!cart) return;
    if (!discountCode.trim()) return;

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      // Filter out the code to remove
      const currentCodes = cart.discountCodes?.filter((code) => code.code !== discountCode) || [];
      const codesToKeep = currentCodes.map((code) => code.code);

      const { data, errors } = await client.request<CartDiscountCodesUpdateMutation>(
        CartOperations.ApplyDiscountCodes,
        {
          variables: {
            cartId: cart.id,
            discountCodes: codesToKeep,
          },
        },
      );

      if (errors || data?.cartDiscountCodesUpdate?.userErrors?.length) {
        const errorMessage =
          (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
          data?.cartDiscountCodesUpdate?.userErrors?.[0]?.message ||
          "Error removing discount code";
        console.error("🚨 → 🛒 → %cFailed to remove discount code", "font-style: italic", errorMessage);
        throw new Error(errorMessage);
      }

      if (!data?.cartDiscountCodesUpdate?.cart) {
        console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
        throw new Error("Failed to remove discount code");
      }

      console.info("🛒 → %cDiscount code removed", "font-style: italic", discountCode);
      dispatch({ type: "set_cart", payload: data.cartDiscountCodesUpdate.cart });
    } catch (error) {
      console.error("🚨 → 🛒 → %cException removing discount code", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  /**
   * Remove all discount codes from cart
   */
  const removeAllDiscountCodes = async () => {
    if (!cart) return;

    dispatch({ type: "set_status", payload: { type: "updating" } });

    try {
      const { data, errors } = await client.request<CartDiscountCodesUpdateMutation>(
        CartOperations.ApplyDiscountCodes,
        {
          variables: {
            cartId: cart.id,
            discountCodes: [],
          },
        },
      );

      if (errors || data?.cartDiscountCodesUpdate?.userErrors?.length) {
        const errorMessage =
          (errors && typeof errors === "object" && "message" in errors ? String(errors.message) : null) ||
          data?.cartDiscountCodesUpdate?.userErrors?.[0]?.message ||
          "Error removing discount codes";
        console.error("🚨 → 🛒 → %cFailed to remove all discount codes", "font-style: italic", errorMessage);
        throw new Error(errorMessage);
      }

      if (!data?.cartDiscountCodesUpdate?.cart) {
        console.error("🚨 → 🛒 → %cNo cart data returned", "font-style: italic");
        throw new Error("Failed to remove all discount codes");
      }

      console.info("🛒 → %cAll discount codes removed", "font-style: italic");
      dispatch({ type: "set_cart", payload: data.cartDiscountCodesUpdate.cart });
    } catch (error) {
      console.error("🚨 → 🛒 → %cException removing all discount codes", "font-style: italic", error);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

  /**
   * Trigger view cart event for analytics
   */
  const trackViewCart = () => {
    if (!cart) return;

    const lines = cart.lines.edges.map((edge) => edge.node);

    viewCartEvent({
      items: lines.map((line) => ({
        id: line.merchandise.product.id,
        name: line.merchandise.product.title,
        category: line.merchandise.product.productType,

        quantity: line.quantity,
      })),
      value: Number.parseFloat(cart.cost.totalAmount.amount),
      currency: cart.cost.totalAmount.currencyCode,
      quantity: cart.totalQuantity,
      voucher: cart.discountCodes?.find((code) => code.applicable)?.code,
    });
  };

  /**
   * Trigger checkout initiated event for analytics
   */
  const trackInitiateCheckout = () => {
    if (!cart) return;

    const lines = cart.lines.edges.map((edge) => edge.node);

    initiateCheckoutEvent({
      items: lines.map((line) => ({
        id: line.merchandise.product.id,
        name: line.merchandise.product.title,
        category: line.merchandise.product.productType,
        quantity: line.quantity,
      })),
      value: Number.parseFloat(cart.cost.totalAmount.amount),
      currency: cart.cost.totalAmount.currencyCode,
      quantity: cart.totalQuantity,
      voucher: cart.discountCodes?.find((code) => code.applicable)?.code,
    });
  };

  // Helper functions
  const isLoading = status.type === "loading";
  const isUpdating = status.type === "updating";
  const isReady = status.type === "ready";
  const isError = status.type === "error";
  const errorMessage = status.type === "error" ? status.message : null;

  // Transform cart lines from paginated object to flat array
  const lines = cart?.lines?.edges?.map((edge) => edge.node) || [];
  const isEmpty = !lines.length;
  const totalItems = cart?.totalQuantity || 0;

  const subtotal = cart?.cost?.subtotalAmount?.amount ? Number.parseFloat(cart.cost.subtotalAmount.amount) : 0;
  const total = cart?.cost?.totalAmount?.amount ? Number.parseFloat(cart.cost.totalAmount.amount) : 0;
  const currencyCode = cart?.cost?.totalAmount?.currencyCode || "";

  const hasDiscounts = cart?.discountCodes && cart.discountCodes.length > 0;
  const appliedDiscounts = cart?.discountCodes?.filter((code) => code.applicable) || [];

  const discountAmount = cart?.discountAllocations?.reduce((total: number, allocation) => {
    const amount = Number.parseFloat(allocation.discountedAmount.amount);
    return total + (Number.isNaN(amount) ? 0 : amount);
  }, 0) || 0;

  return {
    cart,
    status,
    isLoading,
    isUpdating,
    isReady,
    isError,
    errorMessage,

    // Cart data helpers
    isEmpty,
    totalItems,
    subtotal,
    total,
    currencyCode,
    lines,

    // Discount related helpers
    hasDiscounts,
    appliedDiscounts,
    discountAmount,

    // Cart operations
    addLineItems,
    addItem,
    updateQuantity,
    removeLineItems,

    // Discount operations
    applyDiscountCode,
    removeDiscountCode,
    removeAllDiscountCodes,

    // Analytics tracking
    trackViewCart,
    trackInitiateCheckout,
  };
}
