import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { twMerge } from "tailwind-merge";

import ChevronRight from "../../../../assets/icons/chevronRight.svg";
import Image from "../../../image";
import Link from "../../../link";
import RichText from "../../../richText";
import Slice from "../../../slice";
import Transition from "../../../transition";

const closeHeaderMenu = () => document.getElementById("hamburger")?.parentElement?.click();

const NavBlock: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationBlock
    | Queries.PrismicDomainDataBody2NavigationBlock
    | Queries.PrismicDomainDataBody3NavigationBlock
  >
> = ({ slice }) => {
  const { primary, items } = slice;
  return (
    <div className="flex flex-col col-span-full py-5 px-5 mb-5 border-b border-b-black-10">
      <RichText data={primary?.title?.richText} className="block my-2 text-legal text-black-70" />
      {items.map((item, index) => {
        if (!item) return null;
        return (
          <Menu.Item
            as={Link}
            key={index}
            link={item.link}
            onClick={closeHeaderMenu}
            className="mt-2 border-b-0 animated-link h-[26px]"
          >
            <PrismicRichText field={item.title?.richText} fallback={null} />
          </Menu.Item>
        );
      })}
    </div>
  );
};

const NavImage: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationImage
    | Queries.PrismicDomainDataBody2Image
    | Queries.PrismicDomainDataBody3NavigationImage
  >
> = ({ slice }) => {
  const { primary } = slice;
  return (
    <MenuItem
      as={Link}
      link={primary.link}
      className="relative col-span-full mx-2 mb-1 rounded-md border-b-0"
      onClick={closeHeaderMenu}
    >
      <div className="overflow-hidden rounded-md">
        <Image image={primary.image} className="w-full h-auto" />
      </div>
      <span className="absolute top-0 left-0 w-full h-full rounded-md image-overlay" />
      <RichText
        data={primary.title?.richText}
        className="flex absolute top-0 items-end py-4 px-5 w-full h-full font-bold text-base_sm text-white-default"
      />
    </MenuItem>
  );
};

const NavImageWithTitleAndLead: React.FC<
  Slice<
    | Queries.PrismicDomainDataBody1NavigationImageTitle
    | Queries.PrismicDomainDataBody2ImageWithTitleAndLead
    | Queries.PrismicDomainDataBody3NavigationImageTitle
  >
> = ({ slice }) => {
  const { primary } = slice;

  const element = { link: null, ...primary };
  if (!element.link) return null;

  return (
    <MenuItem as={Link} link={element.link} onClick={closeHeaderMenu} className="col-span-full border-b-0">
      <div className="grid grid-cols-3 gap-2 px-2 mb-5">
        <Image image={primary.image} className="overflow-hidden w-full h-auto rounded-md" />
        <div className="col-span-2">
          <span className="block mt-4 mb-2 font-bold text-base_sm">
            <PrismicRichText field={primary.title?.richText} fallback={null} />
          </span>
          <span className="styled-lis blockt text-base_sm text-black-70">
            <PrismicRichText field={primary.lead?.richText} fallback={null} />
          </span>
        </div>
      </div>
    </MenuItem>
  );
};

type Props = {
  title: string | null;
  body: Queries.MenuItemsFragment["body1"] | Queries.MenuItemsFragment["body2"] | Queries.MenuItemsFragment["body3"];
  className?: string;
};

const NavItem: React.FC<Props> = ({ title, body, className: _className }) => {
  if (!title) return null;

  const components = {
    navigation_block: NavBlock,
    navigation_image: NavImage,
    image: NavImage,
    navigation_image___title: NavImageWithTitleAndLead,
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton
            role="menuitem"
            className={twMerge(
              "text-base_sm base-transition-200 flex w-full items-center justify-between border-b border-b-black-10 px-3 font-medium",
              _className,
              open
                ? "fixed top-0 z-[52] h-[72px] w-[inherit] bg-white-default pb-[22px] pt-[23px] text-black-default"
                : "py-[23px] text-black-70 hover-focus:text-blue-primary",
            )}
          >
            {open && <ChevronRight width="20" height="20" className="block ml-3 rotate-180 base-transition-150" />}
            <span className={open ? "mx-auto pr-8 pt-1" : "pl-3 pt-1"}>{title}</span>
            {!open && <ChevronRight width="20" height="20" className="block mr-3 base-transition-150" />}
          </MenuButton>
          <Transition
            show={open}
            as="div"
            className="absolute z-40 w-full h-screen top-[72px] max-w-screen-max bg-white-default pb-[96px]"
          >
            <MenuItems className="overflow-scroll overscroll-contain relative gap-0 content-start pb-16 max-w-screen-xl h-full baseGrid no-scrollbar">
              <Slice slices={body} components={components} />
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export const NavItemMobileAsLink: React.FC<{
  title: string | null;
  link: Queries.LinkFragment | null;
  className?: string;
}> = ({ title, link, className }) => {
  if (!title || title === "" || !link) return null;

  return (
    <div role="menuitem">
      <Link
        link={link}
        className={twMerge(
          "text-base_sm flex w-full items-center justify-between border-b border-b-black-10 px-3 font-medium",
          className,
          "py-[23px] text-black-70 hover-focus:text-blue-primary",
        )}
        onClick={closeHeaderMenu}
      >
        <span className="pt-1 pl-3">{title}</span>
        <ChevronRight width="20" height="20" className="block mr-3" />
      </Link>
    </div>
  );
};

export default NavItem;
