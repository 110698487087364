import React from "react";
import AnimateHeight from "react-animate-height";

type Props = {
  height: "auto" | number | `${number}%`;
  children?: React.ReactNode;
  duration?: number;
  animateOpacity?: boolean;
  className?: string;
};

// https://muffinman.io/react-animate-height/
const TransitionHeight: React.FC<Props> = ({
  children,
  height,
  duration = 300,
  animateOpacity = true,
  className = "",
}) => {
  return (
    <AnimateHeight
      duration={duration}
      height={height}
      easing="ease-out"
      animateOpacity={animateOpacity}
      className={className}
    >
      {children}
    </AnimateHeight>
  );
};

export default TransitionHeight;
