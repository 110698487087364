import { PrismicRichText } from "@prismicio/react";
import type * as prismicT from "@prismicio/types";
import { graphql } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import Link from "./link";

type Props = {
  data?: Maybe<Queries.RichTextFragment>;
  className?: string;
};

const RichText: React.FC<Props> = ({ data, className }) => {
  return (
    <span className={className}>
      <PrismicRichText
        field={data as unknown as prismicT.RichTextField}
        components={{
          hyperlink: ({ node, text, key }) => {
            return (
              <Link key={key} link={node.data as Queries.LinkFragment}>
                {text}
              </Link>
            );
          },
          preformatted: ({ node, key }) => (
            <ReactMarkdown key={key} remarkPlugins={[gfm]}>
              {node.text}
            </ReactMarkdown>
          ),
        }}
        fallback={null}
      />
    </span>
  );
};

export const query = graphql`
  fragment RichText on PrismicRichTextField {
    richText
    text
  }
`;

export default RichText;
