import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import type { SliceComponentProps } from "@prismicio/react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import React from "react";
import { twJoin } from "tailwind-merge";

import Image from "../../../image";
import Link from "../../../link";
import RichText from "../../../richText";
import Slice from "../../../slice";

const NavBlock: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationBlock
    | Queries.PrismicDomainDataBody2NavigationBlock
    | Queries.PrismicDomainDataBody3NavigationBlock,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary, items } = slice;
  return (
    <div className="flex flex-col col-span-6 border-b-0">
      <RichText data={primary?.title?.richText} className="block my-2 text-legal text-black-70" />
      {items.map((item, index) => {
        if (!item) return null;
        return (
          <MenuItem
            as={Link}
            key={index}
            link={item.link}
            onClick={() => context.closeNavigation()}
            className="mt-2 border-b-0 base-transition-100 h-[26px] child-span:border-blue-primary hover-focus:text-blue-primary hover-focus:child-span:border-b"
          >
            <PrismicRichText field={item.title?.richText} fallback={null} />
          </MenuItem>
        );
      })}
    </div>
  );
};

const NavImage: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationImage
    | Queries.PrismicDomainDataBody2Image
    | Queries.PrismicDomainDataBody3NavigationImage,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary } = slice;
  return (
    <MenuItem
      as={Link}
      link={primary.link}
      className="overflow-hidden relative col-span-6 rounded-md border-b-0"
      onClick={() => context.closeNavigation()}
    >
      <Image image={primary.image} className="overflow-hidden w-full h-auto rounded-md" />
      <span className="absolute top-0 left-0 w-full h-full image-overlay" />
      <RichText
        data={primary.title?.richText}
        className="flex absolute top-0 items-end py-4 px-5 w-full h-full font-bold text-base_sm text-white-default"
      />
    </MenuItem>
  );
};
const NavImageWithTitleAndLead: React.FC<
  SliceComponentProps<
    | Queries.PrismicDomainDataBody1NavigationImageTitle
    | Queries.PrismicDomainDataBody2ImageWithTitleAndLead
    | Queries.PrismicDomainDataBody3NavigationImageTitle,
    { closeNavigation: () => void }
  >
> = ({ slice, context }) => {
  const { primary } = slice;

  const element = { link: null, ...primary };
  if (!element.link) return null;

  return (
    <MenuItem
      as={Link}
      link={element.link}
      className="block col-span-6 border-b-0 animated-image"
      onClick={() => context.closeNavigation()}
    >
      <Image image={primary.image} className="overflow-hidden w-full h-auto rounded-md" />
      <RichText data={primary.title?.richText} className="block pr-2 mt-4 mb-2 font-bold text-base_sm" />
      <RichText data={primary.lead?.richText} className="block pr-2 text-base_sm text-black-70" />
    </MenuItem>
  );
};

type Props = {
  title: string | null;
  body: Queries.MenuItemsFragment["body1"] | Queries.MenuItemsFragment["body2"] | Queries.MenuItemsFragment["body3"];
  openNavigation: () => void;
  closeNavigation: () => void;
  toggleNavigation: () => void;
  state: {
    isOpen: boolean;
    itemOpen: boolean;
  };
};

const NavItem: React.FC<Props> = ({ title, body, openNavigation, closeNavigation, toggleNavigation, state }) => {
  if (!title) return null;

  const { itemOpen, isOpen } = state;

  // IMPORTANT: we habe 2 different animations:
  //  1) sliding down when menu is not open and
  //  2) fading in/out  when menu and only the content inside it is changing
  const animation = { slide: isOpen, fade: itemOpen };

  const components = {
    navigation_block: NavBlock,
    navigation_image: NavImage,
    image: NavImage,
    navigation_image___title: NavImageWithTitleAndLead,
  };

  return (
    <Menu as="div" className="mb-[-1px]">
      <MenuButton
        onMouseEnter={() => openNavigation()}
        onClick={() => toggleNavigation()}
        className={twJoin(
          "text-base_sm base-transition-150 flex border-b border-b-transparent px-3 pb-[24px] pt-[23px] font-medium text-black-70",
          itemOpen && "z-50 border-b-blue-primary text-blue-primary",
        )}
      >
        {title}
      </MenuButton>
      <section
        className={twJoin(
          "base-transition-300 absolute left-0 top-[72px] h-[365px] w-full overflow-hidden",
          animation.slide ? "bottom-0" : "bottom-[365px]",
          animation.fade ? "opacity-100" : "opacity-0",
          itemOpen ? "z-50" : "!pointer-events-none",
        )}
      >
        <MenuItems
          static
          as="div"
          className={twJoin(
            "base-transition-300 relative mx-auto h-[365px] w-full max-w-screen-max",
            animation.slide ? "bottom-0" : "bottom-[365px]",
            animation.fade ? "opacity-100" : "opacity-0",
          )}
        >
          <div className="gap-x-0.5 my-8 mx-24 max-w-screen-xl xl:mx-auto baseGrid">
            {body.length === 3 && <div className="col-span-3" />}
            {body.length === 2 && <div className="col-span-6" />}
            <div className="contents">
              <Slice slices={body} components={components} context={{ closeNavigation }} />
            </div>
          </div>
        </MenuItems>
      </section>
    </Menu>
  );
};

export const NavItemAsLink: React.FC<{
  title: string | null;
  link: Queries.LinkFragment | null;
}> = ({ title, link }) => {
  if (!title || title === "" || !link) return null;

  return (
    <div className="pb-[1px]">
      <Link
        link={link}
        className="flex px-3 font-medium border-b text-base_sm base-transition-100 border-b-transparent pb-[22px] pt-[23px] text-black-70 hover-focus:z-50 hover-focus:border-b-blue-primary hover-focus:text-blue-primary"
      >
        {title}
      </Link>
    </div>
  );
};

export const query = graphql`
  fragment MenuItemImageRectangular on PrismicImageField {
    alt
    gatsbyImageData(width: 307, height: 205, placeholder: BLURRED)
  }
  fragment MenuItemImageSquare on PrismicImageField {
    alt
    gatsbyImageData(width: 307, height: 307, placeholder: BLURRED)
  }

  fragment MenuItems on PrismicDomainData {
    nav_one_title
    nav_one_menu_as_link
    nav_one_link {
      ...Link
    }
    body1 {
      ... on PrismicDomainDataBody1NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody1NavigationImage {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
      ... on PrismicDomainDataBody1NavigationImageTitle {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
    }

    nav_two_title
    nav_two_menu_as_link
    nav_two_link {
      ...Link
    }
    body2 {
      ... on PrismicDomainDataBody2NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody2Image {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
      ... on PrismicDomainDataBody2ImageWithTitleAndLead {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageSquare
          }
        }
      }
    }

    nav_three_title
    nav_three_menu_as_link
    nav_three_link {
      ...Link
    }
    body3 {
      ... on PrismicDomainDataBody3NavigationBlock {
        ...Slice
        primary {
          title {
            ...RichText
          }
        }
        items {
          title {
            ...RichText
            text
          }
          link {
            ...Link
          }
        }
      }
      ... on PrismicDomainDataBody3NavigationImage {
        ...Slice
        primary {
          title {
            ...RichText
          }
          link {
            ...Link
          }
          image {
            ...MenuItemImageRectangular
          }
        }
      }
      ... on PrismicDomainDataBody3NavigationImageTitle {
        ...Slice
        primary {
          title {
            ...RichText
          }
          lead {
            ...RichText
          }
          link: link_target {
            ...Link
          }
          image {
            ...MenuItemImageRectangular
          }
        }
      }
    }
  }
`;

export default NavItem;
