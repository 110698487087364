import { isBrowser } from "../utils/index";

const name = "viewCart";

export type ViewCartEvent = {
  items: { id: string; name: string; category: string; quantity: number }[];
  value: number;
  currency: string;
  quantity: number;
  voucher?: string;
};

const viewCart = (detail: ViewCartEvent) => {
  if (!isBrowser) return;

  const event = new CustomEvent<ViewCartEvent>(name, { detail });

  console.info(`🎯 → %c${event.type}`, "font-style: italic", event.detail);

  window.dispatchEvent(event);
};

export const register = (fn: (e: CustomEvent<ViewCartEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true });

export default viewCart;
