import type { Instance } from "../config";
import Config, { type Language, type PrismicLanguage } from "../config";
import { getI18NextLangCode } from "../i18n/index";

const prefix: Record<
  "article" | "dentist" | "help" | "location" | "marketing" | "shop" | "treatment" | "treatmentAddon",
  Record<Language, string>
> = {
  article: { de: "artikel", fr: "article", en: "article", pt: "artigo", cn: "article" },
  help: { de: "faq", fr: "faq", en: "faq", pt: "faq", cn: "faq" },
  dentist: { de: "zahnarzt", fr: "dentiste", en: "dentist", pt: "dentista", cn: "dentist" },
  location: { de: "standort", fr: "emplacement", en: "location", pt: "localidade", cn: "location" },
  marketing: { de: "special", fr: "special", en: "special", pt: "special", cn: "special" },
  shop: { de: "shop", fr: "shop", en: "shop", pt: "shop", cn: "shop" },
  treatment: { de: "behandlung", fr: "traitement", en: "treatment", pt: "tratamento", cn: "treatment" },
  treatmentAddon: { de: "addon", fr: "addon", en: "addon", pt: "addon", cn: "addon" },
};

const getSlugWithLang = (lang: Language | string, to: string, instance: Instance): string => {
  if (to == null) throw new Error("Missing to");

  const slug = to.startsWith("/") ? to : `/${to}`;

  if (lang === Config(instance).shop.lang.main) {
    return slug === "/" ? "/" : `${slug}/`;
  }

  return slug === "/" ? `/${lang}/` : `/${lang}${slug}/`;
};

type Props = {
  type: string;
  lang: PrismicLanguage | string;
  uid: string;
  instance?: Instance;
};
const Router = (props: Props): string => {
  const { uid, type, lang, instance = process.env.GATSBY_SHOP_INSTANCE } = props;

  if (!uid || !type || !lang) throw new Error(`Missing information ${JSON.stringify(props)}`);

  const language = getI18NextLangCode(lang);

  switch (type) {
    case "page_home":
      return getSlugWithLang(language, "/", instance);

    case "page_404":
      return getSlugWithLang(language, uid, instance);
    case "page_content":
      return getSlugWithLang(language, uid, instance);
    case "page_static":
      return getSlugWithLang(language, uid, instance);
    case "page_cart":
      return getSlugWithLang(language, uid, instance);

    case "page_article_overview":
      return getSlugWithLang(language, uid, instance);
    case "page_article":
      return getSlugWithLang(language, `${prefix.article[language]}/${uid}`, instance);

    case "page_shop":
      return getSlugWithLang(language, uid, instance);
    case "page_shop_category":
      return getSlugWithLang(language, `${prefix.shop[language]}/${uid}`, instance);
    case "product":
      return getSlugWithLang(language, `${prefix.shop[language]}/${uid}`, instance);

    case "page_treatments_overview":
      return getSlugWithLang(language, uid, instance);
    case "treatment":
      return getSlugWithLang(language, `${prefix.treatment[language]}/${uid}`, instance);

    case "page_treatment_addons_overview":
      return getSlugWithLang(language, uid, instance);
    case "treatment_addon":
      return getSlugWithLang(language, `${prefix.treatmentAddon[language]}/${uid}`, instance);

    case "page_locations_overview":
      return getSlugWithLang(language, uid, instance);
    case "page_location_partner":
      return getSlugWithLang(language, uid, instance);
    case "location":
      return getSlugWithLang(language, `${prefix.location[language]}/${uid}`, instance);

    case "page_help_overview":
      return getSlugWithLang(language, uid, instance);
    case "page_help_category":
      return getSlugWithLang(language, `${prefix.help[language]}/${uid}`, instance);
    case "faq_question":
      return getSlugWithLang(language, `${prefix.help[language]}/${uid}`, instance);

    case "marketing_treatment":
      return getSlugWithLang(language, `${prefix.marketing[language]}/${prefix.treatment[language]}/${uid}`, instance);

    case "page_dentist":
      return getSlugWithLang(language, `${prefix.dentist[language]}/${uid}`, instance);

    default:
      throw new Error(`Invalid document type: ${type}`);
  }
};

export { getSlugWithLang, Router };
