import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/pt";

import dayjs from "dayjs";
import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import Config from "../config";
import cn from "./trans/cn.json";
import de from "./trans/de.json";
import en from "./trans/en.json";
import fr from "./trans/fr.json";
import pt from "./trans/pt.json";

void use(initReactI18next).init({
  resources: {
    en: { translation: en },
    cn: { translation: cn },
    de: { translation: de },
    fr: { translation: fr },
    pt: { translation: pt },
  },
  lng: Config().shop.lang.main,
  fallbackLng: Config().shop.lang.main,
  supportedLngs: Config().shop.lang.enabled,
  preload: Config().shop.lang.enabled,

  // debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false, // react protects already from xss
    format: (value, format, lng) => {
      if (value instanceof Date) {
        return dayjs(value)
          .locale(lng ?? "en")
          .format(format);
      }

      return value;
    },
  },
});
