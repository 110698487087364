// export { stores } from "../components/map/stores"
import type { Instance } from "./config";
import { configuration } from "./config";
import type { Language, PrismicLanguage } from "./language";
export { isCurrentInstance, ShopInstance } from "./config";
export { languageToPrismicMap, prismicToLanguageMap } from "./language";
export { Router } from "./router";

export default configuration;
export type { Instance, Language, PrismicLanguage };
