import React from "react";

import { useCart } from "../../../provider/shopify/useCart";

import Link from "../../link";
import { Icon } from "../header";

type Props = {
  item: NonNullable<Queries.MenuRestFragment["items"]>[0];
  withTitle: boolean;
};

const Cart: React.FC<Props> = ({ item, withTitle }) => {
  const { totalItems, isLoading } = useCart();

  if (!item?.nav_link) return null;

  return (
    <Link
      link={item.nav_link}
      className="flex relative justify-start w-5 h-5 border-b-0 lg:w-auto lg:h-auto base-transition-100 text-link_sm hover-focus:border-b-blue-primary hover-focus:text-blue-primary hover-focus:child-span:border-blue-primary"
    >
      {!isLoading && totalItems > 0 && (
        <span className="flex absolute -top-0.5 justify-center items-center w-3 h-3 font-semibold text-center rounded-full left-[10px] bg-blue-primary text-[8px] text-white-default">
          {totalItems}
        </span>
      )}
      {item.nav_icon && <Icon name={item.nav_icon} />}
      <span className="border-b border-transparent lg:ml-1 base-transition-200">{withTitle && item.nav_title}</span>
    </Link>
  );
};

export default Cart;
