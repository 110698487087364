import { isBrowser } from "../utils/index";

const name = "addToCart";

export type AddToCartEvent = {
  items: { id: string; price: number; name: string; category: string; quantity: number }[];
  value: number;
  currency: string;
};

const addToCart = (detail: AddToCartEvent) => {
  if (!isBrowser) return;

  const event = new CustomEvent<AddToCartEvent>(name, { detail });

  console.info(`🎯 → %c${event.type}`, "font-style: italic", event.detail);

  window.dispatchEvent(event);
};

export const register = (fn: (e: CustomEvent<AddToCartEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true });
export default addToCart;
