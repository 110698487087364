import { PrismicLink } from "@prismicio/react";
import type * as prismicT from "@prismicio/types";
import { graphql, Link as GatsbyLink } from "gatsby";
import React from "react";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { Router } from "../config";

type Props = {
  children: React.ReactNode;
  link?:
    | Queries.LinkFragment
    | {
      uid?: string;
      type?: string;
      lang?: string;
    }
    | string
    | null;
  className?: string;
  onClick?: () => void;
};
const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ link, children, onClick, className: _className, ...rest }, ref) => {
    const className = twMerge("inline-block border-b border-b-black-default", _className);

    if (!children) return null;
    if (!link) {
      return <span className={className}>{children}</span>;
    }

    if (typeof link === "string") {
      return (
        <a ref={ref} href={link} onClick={onClick} className={className}>
          {children}
        </a>
      );
    }

    if (!("link_type" in link)) {
      if (!link.uid || !link.type || !link.lang) return <span className={className}>{children}</span>;
      return (
        <GatsbyLink
          to={Router({
            type: link.type,
            lang: link.lang,
            uid: link.uid,
          })}
          onClick={onClick}
          className={className}
          {...rest}
        >
          {!ref ? children : (
            <span ref={ref} className={className}>
              {children}
            </span>
          )}
        </GatsbyLink>
      );
    }

    switch (link.link_type) {
      case "Web":
        return (
          <PrismicLink
            ref={ref as React.RefObject<HTMLAnchorElement>}
            field={{ ...link, url: link.url } as unknown as prismicT.LinkField}
            className={className}
            onClick={onClick}
          >
            {children}
          </PrismicLink>
        );
      case "Document":
        if (!link.uid || !link.type || !link.lang) return <span className={className}>{children}</span>;
        return (
          <GatsbyLink
            to={Router({
              type: link.type,
              lang: link.lang,
              uid: link.uid,
            })}
            onClick={onClick}
            className={className}
            {...rest}
          >
            {!ref ? children : (
              <span ref={ref} className={className}>
                {children}
              </span>
            )}
          </GatsbyLink>
        );
      case "Any":
        return <span className={className}>{children}</span>;
      default:
        throw new Error(`Invalid Link Type ${link.link_type as string}`);
    }
  },
);

export const query = graphql`
  fragment Link on PrismicLinkField {
    target
    isBroken
    id
    link_type
    lang
    slug
    tags
    type
    uid
    url
  }

  fragment ArticleLink on PrismicPageArticle {
    lang
    type
    uid
  }
`;

Link.displayName = "Link";

export default Link;
