import { Script } from "gatsby";
import React from "react";

import Config from "../../config/index";
import { useGDPR } from "../../provider/gdpr";

const Hotjar: React.FC = () => {
  const consent = useGDPR();
  const id = Config().shop.scripts.hotjar;
  const sv = 6;

  if (!id || !consent?.statistic) return null;

  return (
    <Script strategy="idle" id="hotjar-config">
      {`
         (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${id},hjsv:${sv}};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
        `}
    </Script>
  );
};

export default Hotjar;
