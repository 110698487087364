import { Script } from "gatsby";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Config, { ShopInstance } from "../../config/index";
import { register as addToCart } from "../../events/addToCart";
import { register as initiateCheckout } from "../../events/initiateCheckout";
import { register as newsletterSignup } from "../../events/newsletterSignup";
import { register as pageView } from "../../events/pageView";
import { register as removeFromCart } from "../../events/removeFromCart";
import { register as viewCart } from "../../events/viewCart";
import { register as viewProduct } from "../../events/viewProduct";
import { register as viewTreatment } from "../../events/viewTreatment";
import { register as viewTreatmentAddon } from "../../events/viewTreatmentAddon";
import { useGDPR } from "../../provider/gdpr";

const track = (eventParams: { [key: string]: unknown; event: string }, index = 1): unknown => {
  const eventName = eventParams.event;

  if (typeof window.dataLayer?.push !== "function") {
    if (index > 10) {
      console.error(`🚨 → 🎯 → 🇩 → %c${eventName}`, "font-style: italic", eventParams);
      return;
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇩 → %c${eventName}`, "font-style: italic", eventParams);
    return setTimeout(() => track(eventParams, index + 1), index * 50);
  }

  console.info(`🎯 → 🇩 → %c${eventName}`, "font-style: italic", eventParams);
  return window.dataLayer.push(eventParams);
};

const GTM: React.FC = () => {
  const consent = useGDPR();
  const { i18n } = useTranslation();
  const instance = ShopInstance;
  const id = Config().shop.scripts.gtm;

  useEffect(() => {
    pageView((e) => {
      const {
        detail: { name },
      } = e;
      track({ event: "page_view", page_title: name, instance, locale: i18n.language });
    });

    newsletterSignup(() => track({ event: "sign_up", method: "newsletter" }));

    viewProduct((e) => {
      const {
        detail: { name, id, category, value, currency },
      } = e;
      track({
        event: "view_product",
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }],
      });
    });

    viewTreatment((e) => {
      const {
        detail: { name, id, category, value, currency },
      } = e;

      track({
        event: "view_treatment",
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }],
      });
    });

    viewTreatmentAddon((e) => {
      const {
        detail: { name, id, category, value, currency },
      } = e;

      track({
        event: "view_treatment_addon",
        instance,
        locale: i18n.language,
        currency,
        value,
        items: [{ item_id: id, item_name: name, item_category: category, price: value, quantity: 1 }],
      });
    });

    addToCart((e) => {
      const {
        detail: { items, value, currency },
      } = e;

      track({
        event: "add_to_cart",
        instance,
        locale: i18n.language,
        currency,
        value,
        items: items.map((i) => ({
          item_id: i.id,
          item_name: i.name,
          item_category: i.category,
          price: i.price,
          quantity: i.quantity,
        })),
      });
    });

    removeFromCart((e) => {
      const {
        detail: { items, value, currency },
      } = e;

      track({
        event: "remove_from_cart",
        instance,
        locale: i18n.language,
        currency,
        value,
        items: items.map((i) => ({
          item_id: i.id,
          item_name: i.name,
          item_category: i.category,
          price: i.price,
          quantity: i.quantity,
        })),
      });
    });

    viewCart((e) => {
      const {
        detail: { value, currency, voucher, items },
      } = e;
      track({
        event: "view_cart",
        instance,
        locale: i18n.language,
        value,
        currency,
        coupon: voucher,
        items: items.map((i) => {
          return { item_id: i.id, item_name: i.name, item_category: i.category, quantity: i.quantity };
        }),
      });
    });

    initiateCheckout((e) => {
      const {
        detail: { value, currency, voucher, items },
      } = e;

      track({
        event: "begin_checkout",
        instance,
        locale: i18n.language,
        value,
        currency,
        coupon: voucher,
        items: items.map((i) => {
          return { item_id: i.id, item_name: i.name, item_category: i.category, quantity: i.quantity };
        }),
      });
    });
  }, [i18n.language, instance]);

  // Google Tag Manager is already integrade with gtag.tsx
  if (!id || !consent?.statistic) return null;

  return (
    <>
      <Script strategy="idle" id="gtm-config">
        {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','${id}');
          `}
      </Script>
    </>
  );
};
export default GTM;
