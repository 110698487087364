import { isBrowser } from "../utils/index";

const name = "newsletterSignup";

const newsletterSignup = () => {
  if (!isBrowser) return;

  const event = new CustomEvent(name, { detail: {} });

  console.info(`🎯 → %c${event.type}`, "font-style: italic", event.detail);

  window.dispatchEvent(event);
};

export const register = (fn: () => void) => window.addEventListener(name, fn, { passive: true });

export default newsletterSignup;
