import React from "react";
import { createContext, useContext, useReducer } from "react";

import Config from "../config/index";
import { getWithExpiry, setWithExpiry } from "../utils";

const storageKey = Config().storageKey.gdpr.key;
const ttl = Config().storageKey.gdpr.ttl;

// Types _______________________________________________________________________________________________________________

type Consent = { necessary: boolean; statistic: boolean; updated?: number };

export type State = Consent | undefined;

type Action = { type: "set_consent"; payload: Consent };

// Helper_ _____________________________________________________________________________________________________________

const updateConsent = (dispatch: React.Dispatch<Action>, { necessary, statistic }: Consent) => {
  const consent = { necessary, statistic, updated: new Date().getTime() };

  // if acceptAll: consent shouldn't expire

  const expiry = necessary === true && statistic === true ? 60 * 60 * 24 * 365 * 10 : ttl;
  setWithExpiry(storageKey, consent, expiry);

  dispatch({ type: "set_consent", payload: consent });
};

// Context _____________________________________________________________________________________________________________

const Context = createContext<State>(getWithExpiry<State>(storageKey));
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DispatchContext = createContext<React.Dispatch<Action>>(() => {});

const reducer = (state: State, action: Action): State => {
  const type: string = action.type;

  switch (action.type) {
    case "set_consent": {
      return action.payload;
    }

    default: {
      console.error(`Unknown action: ${type}`);
      return state;
    }
  }
};

const Provider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getWithExpiry<State>(storageKey));

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </Context.Provider>
  );
};

const useGDPR = () => useContext(Context);
const useGDPRDispatch = () => useContext(DispatchContext);

export default Provider;

export { updateConsent, useGDPR, useGDPRDispatch };
