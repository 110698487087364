import { graphql } from "gatsby";

import { isBrowser } from "../utils/index";
import { getCurrencyCode, getPrice, getProductId } from "../utils/shopify";

const name = "viewTreatmentAddon";

export type ViewTreatmentEvent = {
  id: string;
  name: string;
  type: "product_group";
  category: string;
  value: number;
  currency: string;
};

const viewTreatment = (result: Queries.ViewTreatmentEventFragment) => {
  if (!isBrowser || !result.shopify) return;

  const { shopify } = result;

  const event = new CustomEvent<ViewTreatmentEvent>(name, {
    detail: {
      id: getProductId(shopify),
      name: shopify.title,
      type: "product_group",
      category: shopify.productType,
      value: getPrice(shopify),
      currency: getCurrencyCode(shopify),
    },
  });

  console.info(`🎯 → %c${event.type}`, "font-style: italic", event.detail);

  window.dispatchEvent(event);
};

export const register = (fn: (e: CustomEvent<ViewTreatmentEvent>) => void) =>
  window.addEventListener(name, fn as EventListener, { passive: true });

export const query = graphql`
  fragment ViewTreatmentAddonEvent on PrismicTreatmentAddon {
    shopify {
      title
      productType
      ...ShopifyId
      ...ShopifyPrice
      ...ShopifyCurrency
    }
  }
`;
export default viewTreatment;
