import type { Configuration } from "../config";
import { defaults } from "./defaults";

const configuration: Configuration = {
  ...defaults,
  shop: {
    instance: "EU",
    countries: [
      "AT",
      "BE",
      "BG",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GR",
      "HR",
      "HU",
      "HU",
      "IE",
      "IT",
      "LT",
      "LU",
      "LV",
      "MT",
      "NL",
      "PT",
      "PL",
      "RO",
      "SE",
      "SI",
      "SK",
    ],
    url: "https://eu.alpinewhite.com",
    lang: { main: "de", enabled: ["de", "en", "fr"] },
    newsletter: "Re8Pwd",
    sections: {
      shop: true,
      treatments: true,
    },
    partners: [
      "BenuPharmacie",
      "Bipa",
      "Brack",
      "Coop",
      "CoopVitality",
      "Douglas",
      "Flaconi",
      "Galaxus",
      "Haarshop",
      "Lyko",
      "Manor",
      "PerfectHair",
      "PerfectHair",
      "Rossmann",
      "Rotpunkt",
      "ZurRose",
      "DM",
    ],
    scripts: {
      gtm: "GTM-P3K8685",
      hotjar: "457994",
      facebook: "2531602960492053",
      tiktok: "C8QAVDECNF3QQJBPIHE0",
      pinterest: "2613981124848",
      klaviyo: "Y4J68d",
      skimLinks: "243839X1727274",
    },
    stamped: {
      apiKeyPublic: "pubkey-fk9z7i163WWw69Yf7lRb4ukSg27Fd0",
      storeUrl: "shop.alpinewhite.eu",
    },
    shopifyClient: {
      domain: "shopeu.alpinewhite.com",
      accessToken: "dbe28e4f6016c09e29dd8bfaa8abc2b1",
    },
    meta: {
      de: {
        title: "Alpine White | Teeth Whitening Strips | Bleaching ",
        description:
          "Selbst für ein strahlendes Lächeln sorgen. Alpine White bietet ein komplettes Set für Reinigung, Bleaching und Pflege. Entdecke unsere Whitening Strips.",
        keywords: ["Whitening Strips", "Whitestrips", "Bleaching", "Homebleaching", "Whitening Kit", "Zahnpflege"],
      },
      en: {
        title: "Alpine White | Teeth Whitening Strips | Bleaching",
        description:
          "Give yourself a radiant smile with white teeth. Alpine White provides a complete kit for cleaning, bleaching and care. Discover our Whitening Strips.",
        keywords: [
          "Whitening Strips",
          "Whitestrips",
          "Bleaching",
          "Homebleaching",
          "Whitening Kit",
          "Dental Care",
          "White Teeth",
        ],
      },
      fr: {
        title: "Alpine White | Strips Blanchiment Dentaire | Blanchiment ",
        description:
          "Retrouvez un sourire rayonnant et des dents blanches. Alpine White propose une série de produits pour le nettoyage, le blanchiment et le soin des dents.",
        keywords: [
          "Kit Blanchiment Dentaire",
          "Blanchiment",
          "Bandes Blanchissantes",
          "Whitening Strips",
          "Soin Dentaire",
        ],
      },
    },
  },
};

export { configuration };
