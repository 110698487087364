import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import React from "react";
import { createContext, useContext, useEffect, useReducer } from "react";

import Config from "../../config/";
import { get, isBrowser, remove, set } from "../../utils";

import { CartOperations } from "./graphql";
import type { Action, State } from "./types";

const client = createStorefrontApiClient({
  storeDomain: Config().shop.shopifyClient.domain,
  publicAccessToken: Config().shop.shopifyClient.accessToken,
  apiVersion: "2025-01",
});

const storageKey = Config().storageKey.shopify;

const createOrRetrieveCart = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  if (!isBrowser) return;

  dispatch({ type: "set_status", payload: { type: "loading" } });

  try {
    const existingCartId = get<string>(storageKey);

    if (existingCartId) {
      const cartExists = await tryGetExistingCart(existingCartId, dispatch);
      if (cartExists) return;
    }

    await createNewCart(dispatch);
  } catch (error) {
    console.error("Cart creation/retrieval failed:", error);
    dispatch({
      type: "set_status",
      payload: {
        type: "error",
        message: error instanceof Error ? error.message : String(error),
      },
    });
  }
};

const tryGetExistingCart = async (cartId: string, dispatch: React.Dispatch<Action>): Promise<boolean> => {
  try {
    const { data, errors } = await client.request(CartOperations.GetCart, {
      variables: { cartId },
    });

    if (errors) {
      console.error("🚨 → 🛒 → %cFailed to retrieve cart", "font-style: italic", errors);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: errors && Array.isArray(errors) && errors.length > 0 && "message" in errors[0]
            ? String(errors[0].message)
            : "Error retrieving cart",
        },
      });
      return false;
    }

    if (data?.cart) {
      console.info("🛒 → %cRetrieved existing cart", "font-style: italic", data.cart.id);
      dispatch({ type: "set_cart", payload: data.cart });
      return true;
    }

    console.info("🛒 → %cCart with saved ID not found, creating a new one", "font-style: italic", cartId);
    remove(storageKey);
    return false;
  } catch (error) {
    console.error("🚨 → 🛒 → %cFailed to retrieve existing cart", "font-style: italic", error);
    remove(storageKey);
    return false;
  }
};

const createNewCart = async (dispatch: React.Dispatch<Action>): Promise<void> => {
  try {
    const { data, errors } = await client.request(CartOperations.CreateCart);

    if (errors) {
      console.error("🚨 → 🛒 → %cFailed to create cart", "font-style: italic", errors);
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: errors && Array.isArray(errors) && errors.length > 0 && "message" in errors[0]
            ? String(errors[0].message)
            : "Error creating cart",
        },
      });
      return;
    }

    if (!data?.cartCreate?.cart) {
      console.error("🚨 → 🛒 → %cEmpty response when creating cart", "font-style: italic");
      dispatch({
        type: "set_status",
        payload: {
          type: "error",
          message: "Failed to create cart: Empty or invalid response",
        },
      });
      return;
    }

    const cartId = data.cartCreate.cart.id;
    console.info("🛒 → %cCreated new cart", "font-style: italic", cartId);
    set<string>(storageKey, cartId);

    dispatch({ type: "set_cart", payload: data.cartCreate.cart });
  } catch (error) {
    console.error("🚨 → 🛒 → %cException creating cart", "font-style: italic", error);
    dispatch({
      type: "set_status",
      payload: {
        type: "error",
        message: error instanceof Error ? error.message : String(error),
      },
    });
  }
};

const Context = createContext<State>({
  client,
  status: { type: "loading" },
  productCache: {},
});

const DispatchContext = createContext<React.Dispatch<Action>>(() => {});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set_status": {
      return { ...state, status: action.payload };
    }
    case "set_cart": {
      return {
        ...state,
        cart: action.payload,
        status: { type: "ready" },
      };
    }
    case "cache_product": {
      return {
        ...state,
        productCache: {
          ...state.productCache,
          [action.payload.id]: action.payload,
        },
      };
    }
    default: {
      console.error(`Unknown action: ${JSON.stringify(action)}`);
      return state;
    }
  }
};

const Provider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    client,
    status: { type: "loading" },
    productCache: {},
  });

  useEffect(() => {
    void createOrRetrieveCart(dispatch);
  }, []);

  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </Context.Provider>
  );
};

const useShopify = () => useContext(Context);
const useShopifyDispatch = () => useContext(DispatchContext);

export default Provider;

export { useShopify, useShopifyDispatch };
