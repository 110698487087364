import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Config, { type Instance } from "../../config/index";
import { getWithExpiry, setWithExpiry } from "../../utils";
import Dialog from "../dialog";
import { Shop as LanguageSwitcher, ShopImage } from "./_footer/languageSwitcher";

const CountryPopup: React.FC = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState<Instance | null>(null);

  useEffect(() => {
    const { key: storageKey, ttl } = Config().storageKey.countryRedirect;
    const { flag, fallbackInstance } = Config().redirect;
    const searchParams = new URLSearchParams(window.location.search);

    // if key is set, we are on the correct shop
    if (getWithExpiry<boolean>(storageKey) === true) return;

    // if url params is set, we forcefully stay on this shop.
    if (searchParams.get(flag) === "1") {
      setWithExpiry<boolean>(storageKey, true, ttl);
      return;
    }

    void fetch(`https://api.ipstack.com/check?access_key=${Config().ipstack.accessToken}&fields=country_code`)
      .then((response) => response.json())
      .then((result: { country_code: string }) => {
        // eslint-disable-next-line immutable/no-mutation
        if (Config().shop.countries.includes(result.country_code)) {
          setWithExpiry<boolean>(storageKey, true, ttl);
          return;
        }

        const instance = Config().instances.find((i) => Config(i).shop.countries.includes(result.country_code)) ??
          fallbackInstance;

        // No redirect, we are already on the fallbackInstance
        if (Config().shop.instance !== instance) setRedirect(instance);

        return null;
      })
      // eslint-disable-next-line no-console
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") console.error(error);
      });
  }, []);

  const closeModal = useCallback(() => setRedirect(null), []);

  return (
    <Dialog show={redirect !== null} close={closeModal}>
      <div id="dialogTitle" className="font-bold text-sub_lg">
        {t("countryPopup.title", { country: Config().shop.instance })}
      </div>
      <div className="px-5 md:px-10 p-t-md-fluid">
        {t("countryPopup.text", { country: Config().shop.instance, redirect })}
      </div>
      <div className="px-5 md:px-10">
        <LanguageSwitcher instance={redirect || Config().shop.instance} last={false} />
      </div>
      <div className="flex flex-row px-5 md:px-10 p-t-sm-fluid p-b-lg-fluid">
        <ShopImage instance={Config().shop.instance} />
        <div className="p-l-md-fluid">
          <button
            type="button"
            onClick={closeModal}
            className="inline-block border-b border-b-blue-primary font-condensed text-link_lg"
          >
            {t("countryPopup.stay", { country: Config().shop.instance })}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CountryPopup;
