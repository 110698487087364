import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { graphql } from "gatsby";
import React from "react";
import { twJoin } from "tailwind-merge";

import AppStore from "../../assets/images/app_store.svg";
import PlayStore from "../../assets/images/play_store.svg";
import Breakpoint from "../../components/breakpoint";
import PaymentIcon from "../../components/icon/payment";
import useSettings from "../../hooks/useSettings";
import Link from "../link";
import RichText from "../richText";
import Slice from "../slice";
import TransitionHeight from "../transitionHeight";
import LanguageSwitcher from "./_footer/languageSwitcher";
import Newsletter from "./_footer/newsletter";

const NavBlock: React.FC<Slice<Queries.FooterNavigationBlockFragment>> = ({ slice }) => {
  const { primary, items } = slice;

  const mobile = (
    <Disclosure
      as="div"
      className="col-span-full border-t p-t-md-fluid p-b-md-fluid animated-list-item border-t-black-10"
    >
      {({ open }: { open: boolean }) => (
        <>
          <DisclosureButton className="flex justify-between w-full font-bold text-left text-sub">
            <span className="pt-1">{primary.title}</span>
            <div className={twJoin("animated-toggle-button ml-2 h-[15px] w-[15px] min-w-[15px]", open && "open")} />
          </DisclosureButton>
          <TransitionHeight height={open ? "auto" : 0}>
            <DisclosurePanel static as="ul" className="pl-0 list-none m-t-md-fluid m-b-md-fluid">
              {items?.map((item, index) => (
                <li key={item?.link?.target ?? index} className="m-t-xs-fluid">
                  <Link link={item?.link} className="border-b-0">
                    <RichText data={item?.title?.richText} />
                  </Link>
                </li>
              ))}
            </DisclosurePanel>
          </TransitionHeight>
        </>
      )}
    </Disclosure>
  );

  const desktop = (
    <div className="col-span-4 border-0 pl-[27%]">
      <div className="flex justify-between font-bold m-b-sm-fluid text-sub">{primary.title}</div>
      <ul className="block pl-0 list-none">
        {items?.map((item, index) => (
          <li key={item?.link?.target ?? index} className="pl-0 m-b-xs-fluid">
            <Link link={item?.link} className="border-b-0 xl:whitespace-nowrap animated-link">
              <RichText data={item?.title?.richText} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );

  return <Breakpoint mobile={mobile} desktop={desktop} />;
};

const Footer: React.FC = () => {
  const settings = useSettings();
  const components = {
    navigation_block: NavBlock,
    newsletter: Newsletter,
  };

  return (
    <footer className="mx-auto border-t fade-in-fast max-w-screen-max border-t-black-10">
      <section className="col-span-full gap-0 mx-5 max-w-screen-xl md:mx-11 lg:mx-24 xl:mx-auto m-t-xxl-fluid baseGrid lg:gap-x-lg">
        <div className="col-span-full gap-y-0 border-b lg:border-0 baseGrid border-b-black-10 lg:m-b-xl-fluid">
          <Slice slices={settings.body} components={components} />
        </div>
        <div className="col-span-4 row-start-4 md:col-span-12 lg:col-span-7 lg:row-start-2 gap-y-sm">
          <div className="flex empty:hidden gap-x-xs">
            {settings.paymentIcons?.map(({ icon }, index) => <PaymentIcon key={index} icon={icon} />)}
          </div>
          <div className="mt-2 text-legal text-black-70">{settings.paymentText}</div>
        </div>
        <div className="m-b-xl-fluid lg:m-b-md-fluid m-t-xl-fluid col-span-4 flex items-center md:col-span-12 lg:col-span-10 lg:row-start-2 lg:!mb-0 lg:!mt-0 lg:justify-center">
          <LanguageSwitcher />
        </div>
        <div className="m-t-xl-fluid lg: col-span-4 row-start-2 justify-start md:col-span-12 lg:col-span-7 lg:!-mt-5 xl:!-mt-0 xl:flex xl:justify-end">
          {settings.appTitle?.text && (
            <div className="font-bold leading-[120%] xl:text-right">{settings.appTitle?.text}</div>
          )}
          <div className="flex mt-4 lg:mt-2 xl:mt-0 empty:hidden">
            {settings.appStoreLink && (
              <Link link={settings.appStoreLink} className="mr-4 border-b-0 lg:mr-0 xl:ml-4 empty:hidden">
                <AppStore height="36" width="108" />
              </Link>
            )}
            {settings.playStoreLink && (
              <Link link={settings.playStoreLink} className="mr-4 border-b-0 lg:mr-0 lg:ml-4 xl:ml-4 empty:hidden">
                <PlayStore height="36" width="120" />
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col col-span-full border-t lg:flex-row lg:justify-center p-t-md-fluid m-t-md-fluid border-t-black-10 text-legal text-black-70 lg:py-[24px]">
          <div className="flex flex-col lg:flex-row justify-center]">
            {settings.footerLinks?.map((item, index: number) => {
              if (!item || !item.link || !item.text) return null;

              return (
                <Link
                  key={index}
                  link={item.link}
                  className="m-b-sm-fluid lg:m-l-md-fluid animated-link border-b-0 lg:!mb-0"
                >
                  {item.text.text}
                </Link>
              );
            })}
          </div>
          <RichText
            data={settings.copyright?.richText}
            className="space-x-md m-b-lg-fluid lg:m-l-md-fluid flex lg:!mb-0 lg:justify-center"
          />
        </div>
      </section>
    </footer>
  );
};

export const query = graphql`
  fragment FooterNavigationBlock on PrismicDomainDataBodyNavigationBlock {
    ...Slice

    primary {
      title
    }
    items {
      link {
        ...Link
      }
      title {
        ...RichText
      }
    }
  }
  fragment Footer on PrismicDomainData {
    body {
      ...FooterNewsletter
      ...FooterNavigationBlock
    }
    paymentText: payment_text
    paymentIcons: payment_icons {
      icon
    }
    socialIcons: social_icons {
      link: social_link {
        ...Link
      }
      icon
    }
    copyright {
      ...RichText
    }
    footerLinks: links {
      link: footer_link {
        ...Link
      }
      text: footer_link_text {
        text
      }
    }
    appTitle: app_footer_title {
      ...RichText
    }
    appStoreLink: app_store_link {
      ...Link
    }
    playStoreLink: play_store_link {
      ...Link
    }
  }
`;

export default Footer;
