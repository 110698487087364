import { Script } from "gatsby";
import React from "react";
import { useEffect } from "react";

import Config from "../../config";
import { register as addToCart } from "../../events/addToCart";
// import { register as bookAppointment } from '../../../events/bookAppointment'
import { register as initiateCheckout } from "../../events/initiateCheckout";
import { register as newsletterSignup } from "../../events/newsletterSignup";
import { register as pageView } from "../../events/pageView";
import { register as viewProduct } from "../../events/viewProduct";
import { register as viewTreatment } from "../../events/viewTreatment";
import { register as viewTreatmentAddon } from "../../events/viewTreatmentAddon";
import { useGDPR } from "../../provider/gdpr";

// https://developers.facebook.com/docs/meta-pixel/reference
const _trackWithIndex = (index: number, args: Parameters<typeof window.fbq>): unknown => {
  if (typeof window.fbq !== "function") {
    if (index > 10) {
      return console.error(`🚨 → 🎯 → 🇫 → %c${args[0]}`, "font-style: italic", args.slice(1));
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇫 → %c${args[0]}`, "font-style: italic", args.slice(1));
    return setTimeout(() => _trackWithIndex(index + 1, args), index * 50);
  }

  console.info(`🎯 → 🇫 → %c${args[0]}`, "font-style: italic", args.slice(1));
  return window.fbq(...args);
};

const track = (...args: Parameters<typeof window.fbq>) => {
  _trackWithIndex(1, args);
};

const Facebook: React.FC = () => {
  const consent = useGDPR();
  const id = Config().shop.scripts.facebook;

  useEffect(() => {
    pageView((e) => {
      const {
        detail: { category, name, id },
      } = e;

      track("track", "PageView", {
        content_type: category,
        content_name: name,
        content_ids: [`${id}`],
      });
    });

    newsletterSignup(() => track("track", "Subscribe", { content_type: "type", content_ids: [] }));

    viewProduct((e) => {
      const {
        detail: { type, name, id, value, currency },
      } = e;

      track("track", "ViewContent", {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value,
      });
    });

    viewTreatment((e) => {
      const {
        detail: { type, name, id, value, currency },
      } = e;
      track("track", "ViewContent", {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value,
      });
    });

    viewTreatmentAddon((e) => {
      const {
        detail: { type, name, id, value, currency },
      } = e;
      track("track", "ViewContent", {
        content_type: type,
        content_name: name,
        content_ids: [`${id}`],
        currency,
        value,
      });
    });

    addToCart((e) => {
      const {
        detail: { value, currency, items },
      } = e;

      track("track", "AddToCart", {
        content_type: "product_group",
        content_name: items[0].name,
        contents: items,
        content_ids: items.map(({ id }) => id),
        currency,
        value,
      });
    });

    initiateCheckout((e) => {
      const {
        detail: { items, value, currency },
      } = e;

      track("track", "InitiateCheckout", {
        contents: items,
        content_ids: items.map(({ id }) => id),
        content_type: "product_group",
        currency,
        value,
      });
    });
  }, []);

  if (!id || !consent?.statistic) return null;

  return (
    <Script strategy="idle" id="facebook-config">
      {`
         !function(f,b,e,v,n,t,s)
         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
         n.queue=[];t=b.createElement(e);t.async=!0;
         t.src=v;s=b.getElementsByTagName(e)[0];
         s.parentNode.insertBefore(t,s)}(window, document,'script',
         'https://connect.facebook.net/en_US/fbevents.js');
         fbq('init', '${id}');
        `}
    </Script>
  );
};

export default Facebook;
