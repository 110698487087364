import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/autoplay";
import "swiper/css/virtual";

import { PrismicRichText } from "@prismicio/react";
import type * as prismicT from "@prismicio/types";
import { graphql } from "gatsby";
import React from "react";
import { A11y, Autoplay, EffectCoverflow, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { twJoin } from "tailwind-merge";

import useSettings from "../hooks/useSettings";
import Link from "./link";

const Notice: React.FC = () => {
  const { notices } = useSettings();
  if (!notices || notices.length === 0) return null;

  const isOrange = notices.some((data) => data?.type);

  return (
    <Swiper
      modules={[A11y, EffectCoverflow, Autoplay, Virtual]}
      slidesPerView={1}
      loop={notices.length > 1}
      grabCursor={false}
      autoplay={true}
      centerInsufficientSlides={true}
      spaceBetween={50}
      speed={600}
      effect="coverflow"
      direction="vertical"
      className={twJoin(
        "fade-in-fast !z-[101] overflow-hidden",
        isOrange ? "h-10 bg-sunset-default" : "h-6 bg-blue-primary",
      )}
      id="notice"
      maxBackfaceHiddenSlides={10}
    >
      {notices?.map((data, index) => {
        if (!data) return null;

        return (
          <SwiperSlide
            key={index}
            virtualIndex={index}
            className={twJoin(
              "grid w-full grid-cols-1 items-center justify-center !border-0 text-center text-legal text-white-default child-p:flex child-p:h-full child-p:items-center child-p:justify-center",
              isOrange ? "h-10" : "h-6",
            )}
          >
            <PrismicRichText
              field={data?.notice?.richText as unknown as prismicT.RichTextField}
              components={{
                hyperlink: ({ node, text, key }) => {
                  return (
                    <Link
                      key={key}
                      link={node.data as Queries.LinkFragment}
                      className="base-transition-100 col-span-full inline-block !border-b-0 underline hover-focus:scale-105"
                    >
                      {text}
                    </Link>
                  );
                },
              }}
              fallback={null}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export const query = graphql`
  fragment Notices on PrismicDomainData {
    notices {
      notice {
        ...RichText
      }
      type
    }
  }
`;

export default Notice;
