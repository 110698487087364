import {
  type Instance,
  type Language,
  languageToPrismicMap,
  type PrismicLanguage,
  prismicToLanguageMap,
} from "../config";

const maybePrefixLangWithInstance = (lang: string) => {
  // IMPORTANT: difference between server and browser localisation values causes hydration error;
  // to prevent this, we check for server/browser and simplify localisation for server
  if (typeof window === "undefined") {
    return lang;
  }

  const instance = process.env.GATSBY_SHOP_INSTANCE;

  if (instance === "EU") return lang;

  return `${instance}-${lang}`.toLowerCase();
};
const maybePostfixLangWithInstance = (lang: string) => {
  const instance = process.env.GATSBY_SHOP_INSTANCE;
  if (instance === "EU") return lang;

  return `${lang}-${instance}`.toLowerCase();
};

const dateFormatter = (date: Date, lang: string, options?: Intl.DateTimeFormatOptions): string => {
  // Only tested for CH
  return date.toLocaleString(maybePostfixLangWithInstance(lang), options);
};

const priceFormatter = (price: number, lang: string, currency: string): string => {
  return Intl.NumberFormat(maybePrefixLangWithInstance(lang), {
    currency,
    minimumFractionDigits: Number.isSafeInteger(price) ? 0 : undefined,
    style: "currency",
  }).format(price);
};

const numberFormatter = (number: number, lang: string): string => {
  return Intl.NumberFormat(maybePrefixLangWithInstance(lang), {
    minimumFractionDigits: Number.isSafeInteger(number) ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(number);
};

const percentageFormatter = (percentage: number, lang: string): string => {
  return Intl.NumberFormat(maybePrefixLangWithInstance(lang), {
    maximumFractionDigits: 2,
    style: "percent",
  }).format(percentage);
};

const getPrismicLangCode = (lang: string | Language): PrismicLanguage => languageToPrismicMap[lang as Language];
const getI18NextLangCode = (lang: string | PrismicLanguage): Language => prismicToLanguageMap[lang as PrismicLanguage];

const getHrefLang = (lang: Language, instance: Instance) => {
  switch ([lang, instance].join(",")) {
    case "de,CH":
      return "de-CH";
    case "de,EU":
      return "de-EU";
    case "de,BR":
      return "de-BR";
    case "de,CN":
      return "de-CN";

    case "en,CH":
      return "en-CH";
    case "en,EU":
      return "en-EU";
    case "en,BR":
      return "en-BR";
    case "en,CN":
      return "en-CN";

    case "fr,CH":
      return "fr-CH";
    case "fr,EU":
      return "fr-EU";
    case "fr,BR":
      return "fr-BR";
    case "fr,CN":
      return "fr-CN";

    case "pt,CH":
      return "pt-CH";
    case "pt,EU":
      return "pt-EU";
    case "pt,BR":
      return "pt-BR";
    case "pt,CN":
      return "pt-CN";

    case "cn,CH":
      return "zh-CH";
    case "cn,EU":
      return "zh-EU";
    case "cn,BR":
      return "zh-BR";
    case "cn,CN":
      return "zh-CN";

    default:
      return "en";
  }
};

const getHrefLangWithoutRegion = (lang: Language, instance: Instance) => {
  switch ([lang, instance].join(",")) {
    case "de,CH":
    case "de,EU":
    case "de,BR":
    case "de,CN":
      return "de";

    case "en,CH":
    case "en,EU":
    case "en,BR":
    case "en,CN":
      return "en";

    case "fr,CH":
    case "fr,EU":
    case "fr,BR":
    case "fr,CN":
      return "fr";

    case "pt,CH":
    case "pt,EU":
    case "pt,BR":
    case "pt,CN":
      return "pt";

    case "cn,CH":
    case "cn,EU":
    case "cn,BR":
    case "cn,CN":
      return "zh";

    default:
      return "en";
  }
};

export {
  dateFormatter,
  getHrefLang,
  getHrefLangWithoutRegion,
  getI18NextLangCode,
  getPrismicLangCode,
  maybePostfixLangWithInstance,
  maybePrefixLangWithInstance,
  numberFormatter,
  percentageFormatter,
  priceFormatter,
};
