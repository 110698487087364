import { graphql } from "gatsby";
import React from "react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import Button from "../../../components/button";
import Config from "../../../config";
import newsletterSignup from "../../../events/newsletterSignup";
import useSettings from "../../../hooks/useSettings";
import { getUserData } from "../../../utils/storage";
import SocialIcon from "../../icon/social";
import Link from "../../link";
import RichText from "../../richText";
import SpinnerAnimation from "../../spinnerAnimation";
import Transition from "../../transition";

type FormData = { email: string };
type State = { status: "sending" | "error" | "success" | "form"; message?: string };
type Response = { data: { is_subscribed: boolean }; errors: string[]; success: boolean };

const Newsletter: React.FC<Slice<Queries.FooterNewsletterFragment>> = ({ slice }) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const listId = Config().shop.newsletter;
  const { primary } = slice;
  const [state, setState] = useState<State>({ status: "form" });

  const defaultValues = { email: getUserData()?.email ?? "" };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ defaultValues, mode: "onTouched" });

  // https://github.com/estrattonbailey/klaviyo-subscribe
  // https://community.klaviyo.com/signup-forms-38/trouble-with-embedded-sign-up-form-not-adding-email-to-list-467
  const onSubmit = useCallback(
    async (data: FormData) => {
      setState({ status: "sending" });
      try {
        const body = new URLSearchParams({ g: listId, email: data.email });
        const url = "https://manage.kmail-lists.com/ajax/subscriptions/subscribe";

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Headers": "*",
            Accept: "application/json",
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          redirect: "error",
          body,
        });

        const r = (await response.json()) as Response;

        if (!r.success) {
          setState({ status: "error", message: r.errors[0] ?? undefined });
        } else {
          newsletterSignup();
          setState({ status: "success" });
        }
      } catch (err) {
        setState({ status: "error", message: err as string });
      }
    },
    [listId],
  );

  const showContent = useCallback(() => {
    return (
      <Transition show={true} className="flex m-t-xs-fluid">
        <form onSubmit={void handleSubmit(onSubmit)}>
          {state.status === "form" && (
            <label
              className={twJoin(
                "relative block h-12 w-full rounded rounded-bl rounded-tl border border-r-0 pl-6",
                errors.email && "border-red px-3 py-2.5 hover-focus-active:border-red",
                !errors.email && "!empty:border-black-20 border-black-20 disabled:border-black-20 disabled:opacity-40",
                !errors.email &&
                  "focus-within:border-black-40 focus-visible:border-black-40 hover-focus-active:border-black-40",
              )}
            >
              <input
                type="text"
                placeholder={t("newsletter.enterMail", "Enter E-Mail.")}
                className={twJoin(
                  "text-base_sm z-10 flex h-full w-full items-center border-0 p-0 text-blue-primary",
                  "hover-focus-active:border-0 hover-focus-active:outline-0 hover-focus-active:ring-0",
                )}
                {...register("email", {
                  required: t("newsletter.noEmail", "Missing email."),
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t("newsletter.notValidEmail", "Not a valid email."),
                  },
                })}
              />
              {errors.email && (
                <div className="absolute left-2 mt-5 w-max text-label text-red">{errors.email.message}</div>
              )}
            </label>
          )}
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={state.status !== "form"}
            className={state.status === "form"
              ? "z-20 ml-[-2px] flex h-[48px] items-center rounded-bl-none rounded-tl-none px-6 py-[17px]"
              : "flex h-[48px] w-full items-center px-6 py-[17px]"}
          >
            {state.status === "sending" && (
              <span className="flex justify-between">
                <SpinnerAnimation width="14" height="14" className="mr-2 -ml-1" />
                {t("newsletter.sendingForm", "Sending...")}
              </span>
            )}
            {state.status === "form" && t("newsletter.subscribe", "Subscribe")}
            {state.status === "success" && t("newsletter.success", "Subscribed!")}
            {state.status === "error" && t("newsletter.error", "Error!") + (state.message ? ` ${state.message}` : "")}
          </Button>
        </form>
      </Transition>
    );
  }, [errors.email, handleSubmit, onSubmit, register, state.message, state.status, t]);

  return (
    <div className="m-b-lg-fluid col-span-full lg:col-span-8 lg:pr-[12%]">
      <RichText data={primary?.NewsLetterTitle?.richText} className="block font-bold m-b-xs-fluid text-sub" />
      <RichText data={primary?.lead?.richText} className="block" />
      {showContent()}
      <section className="flex col-span-4 md:col-span-12 lg:col-span-7 lg:justify-start m-b-xs-fluid m-t-sm-fluid">
        {settings.socialIcons?.map((icon, index) => (
          <Link key={index} link={icon?.link} className="mr-4 border-b-0 empty:hidden">
            <SocialIcon
              icon={icon?.icon}
              width="40"
              height="40"
              className={twJoin(
                "h-10 w-10 rounded-full border border-black-20",
                "hover-focus:!border-sky-140 hover-focus:text-sky-140",
              )}
            />
          </Link>
        ))}
      </section>
    </div>
  );
};

export const query = graphql`
  fragment FooterNewsletter on PrismicDomainDataBodyNewsletter {
    ...Slice
    primary {
      lead {
        ...RichText
      }
      NewsLetterTitle: title {
        ...RichText
      }
    }
  }
`;

export default Newsletter;
