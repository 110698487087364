import { graphql } from "gatsby";

const getVariants = (
  variants: readonly Pick<
    Queries.ShopifyProductVariant,
    "availableForSale" | "title" | "shopifyId" | "position" | "price" | "compareAtPrice"
  >[],
) =>
  variants
    .filter((v) => v?.availableForSale)
    .sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    }) || [];

const getId = (value: string): string => {
  const id = value.match(/(?:gid:\/\/shopify\/.*\/)(\d+)(?:.*)$/)?.[1];

  if (!id) throw new Error(`No ShopifyID has the wrong format ${value}`);

  return id;
};

// https://github.com/Shopify/js-buy-sdk/issues/521
const getProductId = (product: Queries.ShopifyIdFragment): string => {
  if (!product.shopifyId) throw new Error("No ShopifyID found");

  return getId(product.shopifyId);
};

const getFirstTag = (product: Queries.ShopifyTagFragment) => {
  const { tags } = product;

  if (!tags || !tags[0]) return null;
  return tags[0];
};

const getPrice = (product: Queries.ShopifyPriceFragment) => {
  const { variants } = product;

  if (!variants || !variants[0] || !variants[0].price) return 0;
  return +variants[0].price;
};

const getCompareAtPrice = (product: Queries.ShopifyCompareAtPriceFragment) => {
  const { variants } = product;
  if (!variants || !variants[0] || !variants[0].compareAtPrice) return null;

  return +variants[0].compareAtPrice;
};

const hasCompareAtPrice = (product: Queries.ShopifyCompareAtPriceFragment) => {
  const { variants } = product;
  if (!variants || !variants[0] || !variants[0].compareAtPrice) return false;

  return true;
};

const getCurrencyCode = (product: Queries.ShopifyCurrencyFragment) => {
  return product.priceRangeV2?.minVariantPrice?.currencyCode ?? null;
};

export const query = graphql`
  fragment ShopifyAvailability on ShopifyProduct {
    ...ShopifyId
    variants {
      availableForSale
      shopifyId
    }
  }
  fragment ShopifyTag on ShopifyProduct {
    tags
  }

  fragment ShopifyVariantId on ShopifyProduct {
    variants {
      shopifyId
    }
  }
  fragment ShopifyId on ShopifyProduct {
    shopifyId
  }

  fragment ShopifyPrice on ShopifyProduct {
    variants {
      price
    }
  }

  fragment ShopifyCurrency on ShopifyProduct {
    priceRangeV2 {
      minVariantPrice {
        currencyCode
      }
    }
  }

  fragment ShopifyCompareAtPrice on ShopifyProduct {
    variants {
      compareAtPrice
    }
  }

  fragment Shopify on PrismicProduct {
    shopify {
      ...ShopifyId
      ...ShopifyPrice
      ...ShopifyCompareAtPrice
      ...ShopifyCurrency
      ...ShopifyTag
      ...ShopifyAvailability
      ...ShopifyVariantId
    }
  }
`;

export {
  getCompareAtPrice,
  getCurrencyCode,
  getFirstTag,
  getId,
  getPrice,
  getProductId,
  getVariants,
  hasCompareAtPrice,
};
