import React from "react";

import Link from "../../../link";
import { Icon } from "../../header";

const closeHeaderMenu = () => document.getElementById("hamburger")?.parentElement?.click();

type Props = { items?: Queries.MenuRestFragment["items"] };

const NavRest: React.FC<Props> = ({ items }) => {
  return (
    <div className="p-5" role="menuitem">
      {items?.map((item, index) => {
        if (!item?.nav_link) return null;
        return (
          <Link
            key={index}
            link={item.nav_link}
            onClick={closeHeaderMenu}
            className="flex justify-start border-b-0 m-r-sm-fluid m-t-md-fluid text-link_sm"
          >
            {item.nav_icon && <Icon name={item.nav_icon} />}
            <span className="pl-1">{item.nav_title}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default NavRest;
