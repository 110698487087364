import "./src/styles/global.css";
import "react-international-phone/style.css";

import type { GatsbyBrowser } from "gatsby";
import React from "react";

import Layout from "./src/components/layout";
import GDPRProvider from "./src/provider/gdpr";
import ShopifyProvider from "./src/provider/shopify";
import Tracking from "./src/services/tracking";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return <Layout>{element}</Layout>;
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <ShopifyProvider>
      <GDPRProvider>
        <Tracking />
        {element}
      </GDPRProvider>
    </ShopifyProvider>
  );
};
