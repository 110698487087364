import { graphql } from "gatsby";
import Provider, { useShopify, useShopifyDispatch } from "./shopify/context";

export default Provider;
export { useShopify, useShopifyDispatch };

export const query = graphql`
  fragment AddToCartContext on PrismicProduct {
    shopify {
      title
      ...ShopifyId
      ...ShopifyPrice
      ...ShopifyCurrency

      productType

      variants {
        availableForSale
        shopifyId
      }
    }
  }
`;
