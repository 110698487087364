import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckCircleFilled from "../../assets/icons/checkCircleFilled.svg";
import Config from "../../config/index";
import { useCart } from "../../provider/shopify/useCart";
import { isBrowser } from "../../utils/index";
import Transition from "../transition";

const key = Config().urlFlags.shopify.discountCode;

// Define a structured state type
type DiscountState = {
  code: string | null;
  processed: boolean;
  visible: boolean;
};

const Discount: React.FC = () => {
  const { t } = useTranslation();
  const [discountState, setDiscountState] = useState<DiscountState>({
    code: null,
    processed: false,
    visible: false,
  });

  const { applyDiscountCode, isUpdating, isReady, appliedDiscounts } = useCart();

  const close = useCallback(() => {
    setDiscountState((prev) => ({
      ...prev,
      visible: false,
    }));
  }, []);

  // Process discount code from URL parameter only once
  useEffect(() => {
    if (!isBrowser || !isReady || discountState.processed) return;

    const params = new URL(window.location.href).searchParams;
    const code = params.get(key);

    // Mark as processed regardless of whether there's a code
    setDiscountState((prev) => ({
      ...prev,
      processed: true,
      code: code || null,
    }));

    if (code) {
      // Apply the discount code
      void applyDiscountCode(code).then(() => {
        setDiscountState((prev) => ({
          ...prev,
          visible: true,
        }));
      });
    }
  }, [applyDiscountCode, discountState.processed, isReady]);

  // Validate if the voucher was successfully applied
  useEffect(() => {
    if (discountState.code && isReady && !isUpdating && discountState.visible) {
      const isApplied = appliedDiscounts.some(
        (discount) => discount.code.toLowerCase() === discountState.code?.toLowerCase(),
      );

      if (!isApplied && appliedDiscounts.length > 0) {
        setDiscountState((prev) => ({
          ...prev,
          visible: false,
        }));
      }
    }
  }, [appliedDiscounts, isReady, isUpdating, discountState.code, discountState.visible]);

  if (!discountState.visible || !discountState.code) return null;

  return (
    <Transition
      show={true}
      as="div"
      className="py-4 mx-5 max-w-screen-xl md:mx-11 lg:mx-24 xl:mx-auto fade-in-fast lg:gap-x-lg"
    >
      <div className="flex relative flex-row justify-between items-center py-2.5 px-5 rounded border base-transition-150 border-sunset-120 bg-sunset-default text-btn text-white-default shadow-snackbar">
        <div className="flex flex-row justify-center items-center">
          <CheckCircleFilled height="32" width="32" className="mr-2.5 text-black-30" />
          <div>
            <div className="font-medium text-label">
              {t("cart.voucherAdded", "Voucher {{ voucher }}", { voucher: discountState.code })}
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={close}
          name="close"
          className="flex absolute top-2 right-4 justify-center items-center w-10 h-10 rounded-sm border border-transparent base-transition-150 text-white-default hover:border-white-70 hover:text-white-70"
          disabled={isUpdating}
        >
          <XMarkIcon className="block w-5 h-5" />
        </button>
      </div>
    </Transition>
  );
};

export default Discount;
