import React from "react";
import { twMerge } from "tailwind-merge";

import Link from "./link";

export type Type = "primary" | "secondary" | "small" | "white";

type Props = {
  type: Type;
  children: React.ReactNode;
  link?: Queries.LinkFragment | { uid: string; type: string; lang: string } | string | null;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const Button: React.FC<Props> = ({ type, children, link, className, onClick, disabled = false }) => {
  switch (type) {
    case "primary":
      className = twMerge(
        "group font-roboto font-medium bg-blue-primary h-12 px-6 border border-blue-primary rounded-lg flex flex-row justify-center items-center !text-white-default text-btn border-solid",
        "base-transition-150",
        "hover-focus:bg-blue-interactive hover-focus:border-blue-interactive hover-focus:shadow-button hover-focus:child-span:bg-blue-interactive",
        "disabled:bg-grey-60 disabled:cursor-not-allowed disabled:border-grey-60",
        "child-span:border-b-0",
        className,
      );
      break;
    case "small":
      className = twMerge(
        "group flex font-roboto font-medium mt-4 w-fit border-b border-b-blue-primary text-link_sm base-transition-150 child-span:base-transition-100 animated-link text-blue-primary whitespace-nowrap child-svg:mt-1",
        "hover-focus:text-sky-140 hover-focus:border-sky-140 hover-focus:child-span:text-sky-140",
        "disabled:!text-grey-disabled disabled:cursor-not-allowed disabled:!border-b-grey-disabled disabled:pointer-events-none",
        className,
      );
      break;
    case "secondary":
      className = twMerge(
        "group font-roboto font-medium h-12 px-6 border border-blue-100 rounded-lg flex flex-row justify-center items-center text-btn base-transition-150 backdrop-blur-[5px] !text-blue-primary",
        "hover-focus-active:border-blue-150 hover-focus-active:!text-blue-200",
        "disabled:border-black disabled:!text-grey-60 disabled:cursor-not-allowed",
        "child-span:border-b-0",
        className,
      );
      break;
    case "white":
      className = twMerge(
        "group font-roboto font-medium h-12 px-6 border border-white-default bg-white-default rounded-lg flex flex-row justify-center items-center text-btn base-transition-150 backdrop-blur-[5px] !text-blue-primary",
        "hover-focus-active:bg-sky-10 hover-focus-active:border-blue-primary hover-focus-active:shadow-button hover-focus-active:!text-blue-primary",
        "disabled:border-black-10 disabled:cursor-not-allowed",
        "child-span:border-b-0",
        className,
      );
      break;
    default:
      throw new Error(`Invalid Button Type: ${type as string}`);
  }

  if (!link) {
    return (
      <button onClick={onClick} type="button" className={className} disabled={disabled}>
        {children}
      </button>
    );
  }

  return (
    <Link link={link} onClick={onClick} className={className}>
      {children}
    </Link>
  );
};

export default Button;
